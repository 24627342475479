import React from 'react';
import styled from '@emotion/styled';

const Duo: React.FC = ({ children }) => {
  return (
    <>
      <Container>{children}</Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin-bottom: -30px;

  @media (min-width: 1200px) {
    align-content: stretch;
  }
`;

export default Duo;
